import { useState } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { baseUrl } from "../localdata";
import IPUrl from "../IP/url";

const Login = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const navigate = useNavigate();
  const Home = () => {
    navigate("/home");
  };
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/login`,
        {
          number,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(data, "login data");
      sessionStorage.setItem("admin", JSON.stringify(data));
      navigate("/home");
    } catch (error) {
      // alert(error.message);
      // alert(error?.response?.data?.msg);
    }
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <div style={{ height: "100vh", display: "flex", width: "100vw" }}>
          <div
            style={{
              width: "40vw",
              height: "100vh",
              background: "#0F9EE8",
              boxShadow: "13px 0px 92px rgba(0, 0, 0, 0.18)",
            }}
          >
            <img className="logo" /*src ={logo}*/ />
            <div
              style={{ width: "12vw", marginTop: "32vh", marginLeft: "6vw" }}
            >
              <h1
                style={{
                  color: "white",
                  fontSize: "60px",
                  marginBottom: "1vh",
                }}
              >
                Login
              </h1>

              <hr
                style={{
                  width: "6.5vw",
                  height: "0.5vh",
                  background: "#FFFFFF",
                  marginLeft: "1vw",
                }}
              />
            </div>
          </div>
          <div
            style={{
              height: "7vh",
              marginLeft: "38vw",
              marginTop: "46.5vh",
              position: "absolute",
              width: "4vw",
              borderRadius: "30px",
              background: "#0F9EE8",
            }}
          >
            <AiOutlineRight
              style={{
                color: "#FFFFFF",
                marginTop: "1.8vh",
                marginLeft: "1.8vw",
                fontSize: "25px",
              }}
            />
          </div>
          <div>
            <div
              style={{ marginTop: "33vh", width: "32vw", marginLeft: "15vw" }}
            >
              <div style={{ marginTop: "4vh" }}>
                <h3
                  style={{
                    color: "#0F9EE8",
                    fontWeight: "500",
                    marginBottom: "1vh",
                  }}
                >
                  Phone Number
                </h3>
                <input
                  type="text"
                  required
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="    Enter your phone number"
                  style={{
                    paddingBottom: "0.8vh",
                    width: "30vw",
                    outline: "0",
                    borderWidth: "0 0 0",
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)",
                    borderRadius: "5px",
                    height: "4vh",
                  }}
                />
              </div>
              <div style={{ position: "relative", marginTop: "4vh" }}>
                <h3
                  style={{
                    color: "#0F9EE8",
                    fontWeight: "500",
                    marginBottom: "1vh",
                  }}
                >
                  Password
                </h3>
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="    Enter password"
                  style={{
                    marginBottom: "-2vh",
                    paddingBottom: "0.8vh",
                    width: "30vw",
                    outline: "0",
                    borderWidth: "0 0 0",
                    boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)",
                    borderRadius: "5px",
                    height: "4vh",
                  }}
                />
                <BsEyeSlash

                  onClick={handleShowPassword}
                  style={{
                    position: "absolute",
                    marginTop: "1.5vh",
                    marginLeft: "-2vw",
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div>
                <Link to="/forgot">
                  <h4
                    style={{
                      color: "#0F9EE8",
                      textAlign: "end",
                      marginRight: "2vw",
                      marginTop: "2vw"
                    }}
                  >
                    Forgot Password
                  </h4>
                </Link>
              </div>
            </div>
            <div>
              <button
                disabled={isSubmitting}
                type="submit"
                style={{
                  height: "5vh",
                  cursor: "pointer",
                  border: "0",
                  color: "#FFFFFF",
                  marginTop: "10vh",
                  marginLeft: "27vw",
                  width: "6vw",
                  background: "#0F9EE8",
                  borderRadius: "7px",
                }}
              >
                Login
              </button>
            </div>
            {/* {isSubmitting && (
              <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                Loading...
              </span>
            )} */}
            <div style={{ marginLeft: "25vw" }}>
              <h4 style={{ color: "#0F9EE8", fontWeight: "600" }}>
                Don't have an account?{" "}
                <Link to="/signup" style={{ color: "#0F9EE8" }}>
                  Sign up
                </Link>
              </h4>
              <span style={{
                color: '#0F9EE8',
                marginLeft: '4vw',
                fontSize: '1.2vmax',
                fontWeight: '500',
                cursor: 'pointer'
              }}
                onClick={() => {
                  window.location.href = '/contact'
                }}
              >Contact Us</span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default Login;
