import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { admin, baseUrl } from "../localdata";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import IPUrl from "../IP/url";

const UpdatePay = () => {
  const [adminId, setId] = useState("");

  const open2 = true;
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  // get payment detail by id api here->
  const { id } = useParams();

  const paymentId = id;




  const [loading, setLoading] = useState(false);
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentTime, setPaymentTime] = useState("");
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [netPrice, setNetPrice] = useState("");
  const [orderQuantity, setOrderQuantity] = useState("");
  const [totalOutstandingPayment, setTotalOutstanding] = useState(
    ""
  );
  const [totalPaidAmount, setTotalPaidAMount] = useState("");
  const [orderNumber, setOrderNumber] = useState("");

  // run useEffect to autofill the form
  useEffect(() => {
    setLoading(true);
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const adminId = admin._id;
    if (adminId) {
      const fetchData = async () => {
        const { data } = await axios.get(
          `${IPUrl}/admin/getPaymentDetails/${paymentId}/${adminId}`
        );
        setLoading(false);
        setPaymentDate(data?.paymentDate);
        setPaymentTime(data?.paymentTime);
        setFullName(data?.fullName);
        setNumber(data?.number);
        setProductName(data?.productName);
        setNetPrice(data?.netPrice);
        setOrderQuantity(data?.orderQuantity);
        setOrderNumber(data?.orderNumber);
        setTotalPaidAMount(data?.totalPaidAmount);
        setTotalOutstanding(data?.totalOutstandingPayment);
      }
      fetchData()
    }
  }, [paymentId])

  // update payment submit form->
  const submitForm = async () => {
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/editPayment/${paymentId}/${adminId}`,
        {
          paymentDate,
          paymentTime,
          fullName,
          number,
          productName,
          netPrice,
          orderQuantity,
          totalOutstandingPayment,
          totalPaidAmount,
          orderNumber,
        }
      );
      toast("Payment Updated");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        {loading ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <form onSubmit={handleSubmit(submitForm)}>
            <div
              style={{
                height: "100vh",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
              <div style={{ display: "flex", paddingTop: "8vh" }}>
                <div>
                  <h3>Date</h3>
                  <input
                    required
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                    type="text"
                    placeholder="    Date"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Time</h3>
                  <input
                    required
                    value={paymentTime}
                    onChange={(e) => setPaymentTime(e.target.value)}
                    type="text"
                    placeholder="    Time"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <h3>Full Name</h3>
                  <input
                    required
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    type="text"
                    placeholder="    Full Name"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Phone Number</h3>
                  <input
                    required
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    type="text"
                    placeholder="    Phone Number"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <h3>Product Name</h3>
                  <input
                    required
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    type="text"
                    placeholder="    Product Name"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Net Price</h3>
                  <input
                    required
                    value={netPrice}
                    onChange={(e) => setNetPrice(e.target.value)}
                    type="text"
                    placeholder="    Net Price"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "4vh" }}>
                <div>
                  <h3>Order Quantity</h3>
                  <input
                    required
                    value={orderQuantity}
                    onChange={(e) => setOrderQuantity(e.target.value)}
                    type="number"
                    placeholder="    Order Quantity"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Order Number</h3>
                  <input
                    required
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                    type="number"
                    placeholder="    Total Outstanding Payment"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Total Outstanding Payment</h3>
                  <input
                    required
                    value={totalOutstandingPayment}
                    onChange={(e) => setTotalOutstanding(e.target.value)}
                    type="number"
                    placeholder="    Total Outstanding Payment"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Total Paid Amount</h3>
                  <input
                    required
                    value={totalPaidAmount}
                    onChange={(e) => setTotalPaidAMount(e.target.value)}
                    type="number"
                    placeholder="    Total Paid Amount"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <button
                style={{
                  color: "#FFFFFF",
                  background: "#7400B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "5vh",
                  marginLeft: "54vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                disabled={isSubmitting}
              >
                ADD
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
export default UpdatePay;
