import React from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import IPUrl from "../IP/url";


const Add = () => {
  const submitForm = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <form onSubmit={submitForm}>
          <div
            style={{
              height: "90vh",
              width: "74vw",
              paddingLeft: "6vw",
              background: "#EFEFEF",
            }}
          >
            <div style={{ display: "flex", paddingTop: "12vh" }}>
              <div>
                <h3>Sr No.</h3>
                <input
                  type="text"
                  required
                  placeholder="    Sr No."
                  style={{ height: "4vh", width: "14vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Date</h3>
                <input
                  type="text"
                  required
                  placeholder="    Date"
                  style={{ height: "4vh", width: "14vw", marginLeft: "2vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Thickness</h3>
                <input
                  type="text"
                  required
                  placeholder="    Thickness"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div>
                <h3>Length</h3>
                <input
                  type="text"
                  required
                  placeholder="    Length"
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Weight Per Pes.</h3>
                <input
                  type="text"
                  required
                  placeholder="    Weight"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "4vh" }}>
              <div>
                <h3>Price</h3>
                <input
                  type="text"
                  required
                  placeholder="    Price"
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Business Type</h3>
                <input
                  type="text"
                  required
                  placeholder="    Business"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <button
              style={{
                color: "#FFFFFF",
                background: "#7400B8",
                height: "4vh",
                width: "10vw",
                marginTop: "18vh",
                marginLeft: "54vw",
                border: "none",
                borderRadius: "9px",
                cursor: "pointer",
              }}
            >
              ADD
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
