import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import upload from "./large.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { admin, baseUrl } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSWR from "swr";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Navigate } from "react-router-dom";

import img from "./img.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import cloud from "./cloud.png";
import IPUrl from "../IP/url";
// import { MdDelete } from "react-icons/md";


const Advertisement = () => {
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const [url, setUrl] = useState("");
  const [advertisementImage, setImage] = useState("");

  const imageRef = useRef();

  const handleClick = () => {
    return imageRef.current;
  };

  const submitForm = async () => {
    const myForm = new FormData();
    myForm.append("url", url);
    console.log(advertisementImage);
    myForm.append("advertisementImage", advertisementImage);

    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/addAdvertisement/${adminId}`,

        myForm,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast("Ad Added");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    console.log(advertisementImage, "line 33");
    console.warn(e.target.files[0]);
  };
  console.log(advertisementImage);
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const spanref = useRef(null);

  useEffect(() => {
    const element = inputRef.current;

    element.addEventListener("change", () => {
      const inputImage = element.files[0];
      spanref.current.innerHTML = inputImage.name;
    });
  });

  // fetch ads-:



  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/getAdvertisement`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  //  delete ads-:

  async function deleteAd(id) {
    try {
      await axios.get(`${IPUrl}/admin/delete/advertisement/${id}`)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div>
          <Navbar />
          <div
            style={{
              height: "97vh",
              width: "80vw",
              overflow: "scroll",
              background: "#EFEFEF",
              paddingTop: "3vh",
            }}
          >

            <div
              style={{
                display: "flex",
                width: "50vw",
                alignItems: "end",
                justifyContent: "space-around",
                marginLeft: "15vw",
              }}
            >
              <select style={{}}>
                <option value="T">Top</option>
                <option value="B">Bottom</option>
              </select>
              <div>
                {/* <h3>URL</h3> */}
                {/* <input
                  required
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  placeholder="    Enter URL"
                  style={{ height: "4vh", width: "36vw" }}
                /> */}

              </div>
              <div style={{ background: "#7400B8", }}>
                <label
                  style={{ height: "5vh", width: "10vw" }}
                  htmlFor="advertisement"
                >
                  <span style={{ color: "#fff", padding: 10, margin: 10 }}>Upload Feature Image</span>
                </label>
                <span
                  style={{ fontSize: "12px", marginTop: "5vh" }}
                  ref={spanref}
                ></span>


                <input
                  ref={inputRef}
                  style={{ display: "none" }}
                  id="advertisement"
                  onChange={handleImageChange}
                  type="file"
                  accept="image"

                />



              </div>
              <div style={{ background: "#7400B8", }}>
                <label
                  style={{ height: "5vh", width: "10vw" }}
                  htmlFor="advertisement"
                >
                  <span style={{ color: "#fff", padding: 10, margin: 10 }}>Upload Main Image</span>
                </label>
                <span
                  style={{ fontSize: "12px", marginTop: "5vh" }}
                  ref={spanref}
                ></span>


                <input
                  ref={inputRef}
                  style={{ display: "none" }}
                  id="advertisement"
                  onChange={handleImageChange}
                  type="file"
                  accept="image"

                />



              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8vh",
              }}
            >
              <button
                type="submit"
                style={{
                  height: "4.72vh",
                  width: "6vw",
                  background: "#7400B8",
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: "9px",
                }}
              >
                Add
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
            <hr style={{ marginTop: "3vh", border: "1px solid #B6B6B6" }} />
            {data?.map((ad, index) => {
              return (
                <div key={index} style={{ display: "flex", marginLeft: "2vw", marginTop: '2vh', alignItems: 'center' }}>
                  <img
                    src={ad.image}
                    style={{
                      width: "10vw",
                      height: "10vh",
                      objectFit: "contain",
                    }}
                  />
                  <input
                    type="text"
                    // placeholder="    https://waltnot.net"
                    value={ad.url}
                    style={{
                      height: "4vh",
                      width: "36vw",
                      marginLeft: "4vw",
                      alignSelf: "center",
                    }}
                  />
                  <MdDelete onClick={() => deleteAd(ad._id)} style={{ color: "crimson", marginLeft: "4vmax", fontSize: "1.2rem", cursor: "pointer" }} />
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </div>
  );
};
export default Advertisement;
