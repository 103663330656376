export const admin = JSON.parse(sessionStorage.getItem("admin"));

export const baseUrl = "https://3.110.184.125:4400";
// export const baseUrl = "http://localhost:4400"
export const states = [
  "West Bengal",
  "Andaman & Nicobar",
  "Daman and Diu and Dadra and Nagar Haveli",
  "Delhi",
  "Haryana",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Puducherry",
  "Tamil Nadu",
  "Chhattisgarh",
  "Telangana",
  "Andhra Pradesh",
  "Goa",
  "Himachal Pradesh",
  "Punjab",
  "Rajasthan",
  "Gujarat",
  "Uttarakhand",
  "Uttar Pradesh",
  "Sikkim",
  "Assam",
  "Arunachal Pradesh",
  "Nagaland",
  "Manipur",
  "Mizoram",
  "Tripura",
  "Meghalaya",
  "Bihar",
  "Ladakh",
  "Jammu and Kashmir",
  "Odisha",
];
