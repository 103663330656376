import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import styles from "./profile.module.scss";
import profile from "./profile.png";
import name from "./name.png";
import number from "./number.png";
import cam from "./cam.png";
import { admin } from "../localdata";
import IPUrl from "../IP/url";


const Profile = () => {
  const [adminId, setId] = useState("");
  const [fullname, setName] = useState("");
  const [number, setNumber] = useState("");
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
      setName(admin.fullName);
      setNumber(admin.number);
    }
  });

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
  };

  console.log(fullname, "inside admin profile");
  const [isOpen, setisOpen] = useState(false);
  const [image, setImage] = useState(null);
  const handleSubmit = () => { };
  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      const imageUrl = reader.result;
      sessionStorage.setItem("image", imageUrl);
      setImage(imageUrl);
    };

    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const storedImageUrl = sessionStorage.getItem("image");
    if (storedImageUrl) {
      setImage(storedImageUrl);
    }
  }, []);
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <div
          style={{
            height: "100vh",
            overflow: "scroll",
            width: "72vw",
            padding: "0 4vw 0 4vw",
            background: "#EFEFEF",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                width: "6vw",
                justifyContent: "space-between",
              }}
            >
              <AiOutlineArrowLeft
                style={{
                  alignSelf: "center",
                  cursor: "pointer",
                  color: "#7400B8",
                }}
                onClick={handleNavigate}
              />
              <h3 style={{ color: "#7400B8" }}>Profile</h3>
            </div>

            <Link to="/editprofile">
              <h4 style={{ color: "#367CFF", cursor: "pointer" }}>Edit</h4>
            </Link>
          </div>
          <form enctype="multipart/form-data" onSubmit={handleSubmit}>
            <div
              style={{
                height: "18vh",
                margin: "12vh 0vw 0 31vw",
                position: "relative",
                border: "4px solid #7400B8",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "10vw",
                borderRadius: "80px",
              }}
            >
              <img
                src={image}
                alt="admin"
                style={{ height: "85%", width: "85%", borderRadius: "50%" }}
              />

              <label
                htmlFor="image-input"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginTop: "8vh",
                  marginLeft: "8.5vw",
                  height: "5.2vh",
                  width: "3vw",
                  backgroundImage: `url(${cam})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <input
                  type="file"
                  id="image-input"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "8vh",
                justifyContent: "space-around",
              }}
            >
              <div>
                <h3 style={{ marginLeft: "1.4vw" }}>Full Name</h3>
                <input
                  defaultValue={fullname}
                  className={styles.fullname}
                  placeholder="Full Name"
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "1.4vw" }}>Phone Number</h3>
                <input
                  defaultValue={number}
                  className={styles.fullname}
                  placeholder="Phone"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Profile;
