import { useState } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import IPUrl from "../IP/url";


const Otp = () => {
    const location = useLocation();
    const state = location.state;
    console.log(state);
    const navigate = useNavigate();
    const toChange = () => {
        navigate("/change")
    }

    const number = state?.number

    const [otp, setOtp] = useState('');

    const submitForm = async (e) => {
        e.preventDefault()
        try {
            const { data } = await axios.post(`${IPUrl}/admin/sendotp`, {
                otp,
                number,
            }
            )
            console.log(data);
            navigate("/change", { state: { id: data } })
        } catch (error) {
            alert(error)
        }
    }
    console.log(otp, number);
    return (
        <>
            <form onSubmit={submitForm}>
                <div style={{ height: "100vh", display: "flex", width: "100vw" }}>
                    <div style={{ width: "40vw", height: "100vh", background: "#0F9EE8", boxShadow: "13px 0px 92px rgba(0, 0, 0, 0.18)" }}>

                        <img className='logo' /*src ={logo}*/ />
                        <div style={{ width: "34vw", marginTop: "32vh", marginLeft: "6vw" }}>
                            <h1 style={{ color: "white", fontSize: "60px", marginBottom: "1vh", }}>OTP</h1>
                            <hr style={{ width: "6.5vw", height: "0.5vh", background: "#FFFFFF", marginLeft: "1vw" }} />
                        </div>



                    </div>
                    <div style={{
                        height: "7vh", marginLeft: "38vw", marginTop: "46.5vh", position: "absolute", width: "4vw", borderRadius: "30px",
                        background: "#0F9EE8"
                    }}>
                        <AiOutlineRight style={{ color: "#FFFFFF", marginTop: "1.8vh", marginLeft: "1.8vw", fontSize: "25px" }} />
                    </div>
                    <div>
                        <div>
                            <h2 style={{ color: "#0F9EE8", marginTop: "18vh", marginLeft: "8vw", fontWeight: "350" }}>Enter OTP to reset your Password</h2>
                        </div>
                        <div style={{ marginTop: "25vh", width: "32vw", marginLeft: "15vw" }}>

                            <div style={{ marginTop: "4vh" }}>
                                <h3 style={{ color: "#0F9EE8", fontWeight: "500", marginBottom: "1vh" }}>OTP</h3>
                                <input required type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder='    Enter OTP' style={{ paddingBottom: "0.8vh", width: "30vw", outline: "0", borderWidth: "0 0 0", boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)", borderRadius: "5px", height: "4vh" }} />
                            </div>

                        </div>
                        <div>
                            <button type="submit" style={{ height: "5vh", cursor: "pointer", border: "0", color: "#FFFFFF", marginTop: "4vh", marginLeft: "27vw", width: "6vw", background: "#0F9EE8", borderRadius: "7px" }}>Send</button>
                        </div>




                    </div>
                </div>
            </form>
        </>
    )
}
export default Otp;