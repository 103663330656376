import { useState, useRef, useEffect } from "react";
import upload from "./large.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import * as XLSX from "xlsx";
import { admin, baseUrl, states } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";
import "./stockname.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IPUrl from "../IP/url";

const B2bUpload = () => {
  const [adminId, setId] = useState("");
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const [stockName, setStockName] = useState("");
  const [businessType, setBusinessType] = useState("");

  const [formData, setFormData] = useState([]);

  const imageRef = useRef();
  const spanref = useRef();

  useEffect(() => {
    const element = imageRef.current;
    if (element) {
      element?.addEventListener("change", () => {
        const inputImage = element.files[0];
        spanref.current.innerHTML = inputImage.name;
      });
    }
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const extractedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const columnData = extractedData
        .slice(0)
        .map((row) => ({ value: row[0] }));

      setFormData(columnData);
    };
    reader.readAsBinaryString(file);
  };

  // create a function to generate input fields dynamically using key value pairs of object
  // const generateInputFields = (object, index) => {
  //   const inputFields = [];
  //   // used for of loop to iterate over the properties of the object->
  //   for (const [key, value] of Object.entries(object)) {
  //     inputFields.push(
  //       <div style={{ display: "flex", gap: "20px" }} key={`${index}-${key}`}>
  //         <label htmlFor={`input-${index}-${key}`}>{key}:</label>
  //         <input
  //           id={`input-${index}-${key}`}
  //           name={`${key}[${index}]`}
  //           defaultValue={value}
  //         />
  //       </div>
  //     );
  //   }
  //   return inputFields;
  // };

  console.log(formData, "line 85");

  const date = new Date();
  const newDate = date.toISOString().substring(0, 10);
  const stockDate = newDate;

  const excelData = formData;
  const submitForm = async (formdata) => {
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/upload/stockStatement/${adminId}`,
        {
          stockName,
          businessType,
          excelData,
        }
      );
      console.log(data, "form data");
      toast("Stock Added");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <form onSubmit={handleSubmit(submitForm)}>
          <div
            style={{
              height: "100vh",
              overflow: "scroll",
              width: "74vw",
              paddingLeft: "6vw",
              background: "#EFEFEF",
            }}
          >
            <div style={{ display: "flex", paddingTop: "6vh" }}>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Stock Name</h3>
                <input
                  type="text"
                  required
                  value={stockName}
                  onChange={(e) => setStockName(e.target.value)}
                  placeholder="    Stock Name"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Business Type</h3>
                <select
                  required
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  name=""
                  id=""
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                >
                  <option value="" selected></option>
                  <option>B2C</option>
                  <option>B2B</option>
                </select>
                {/* <input
                  type="text"
                  required
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  placeholder="   Business Type"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                /> */}
              </div>
            </div>

            <div style={{ display: "flex" }}></div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "1vw" }}
            >
              <label
                htmlFor=""
                style={{ fontWeight: "bold", marginTop: "1vh" }}
              >
                Choose An excel file
              </label>
              <label htmlFor="excel">
                <img
                  src={upload}
                  alt="upload"
                  style={{
                    width: "6vw",
                    cursor: "pointer",
                    marginTop: "2vh",
                  }}
                />
              </label>
            </div>

            <input
              ref={imageRef}
              style={{ display: "none" }}
              id="excel"
              type="file"
              onChange={handleFileUpload}
            />
            <span
              style={{ fontSize: "12px", marginTop: "5vh" }}
              ref={spanref}
            ></span>
            {/* <div style={{ display: "grid" }}>
                {formData.map((data, index) =>
                  generateInputFields(data, index)
                )}
              </div> */}
            <button
              style={{
                color: "#FFFFFF",
                background: "#7400B8",
                height: "4vh",
                width: "10vw",
                marginTop: "4vh",
                marginLeft: "53vw",
                border: "none",
                borderRadius: "9px",
              }}
              type="submit"
            >
              Upload
            </button>
            {isSubmitting && (
              <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                Loading...
              </span>
            )}
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              success
              theme="dark"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default B2bUpload;
