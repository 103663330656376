import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import { useForm } from "react-hook-form";
import { admin, baseUrl, states } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import IPUrl from "../IP/url";

const AddUser = () => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [businessType, setBusinessType] = useState("");

  const submitForm = async (e) => {
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/addUser/${adminId}`,
        {
          fullName,
          number,
          email,
          password,
          zipCode,
          city,
          state,
          businessType,
        },

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("User Added");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <form onSubmit={handleSubmit(submitForm)}>
          <div
            style={{
              height: "97vh",
              width: "80vw",
              paddingLeft: "2vw",
              background: "#EFEFEF",
            }}
          >
            <h2 style={{ paddingTop: "4vh" }}>Basic Information</h2>
            <div style={{ display: "flex" }}>
              <div>
                <h3>Full Name </h3>
                <input
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  type="text"
                  placeholder="    Enter your full name"
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Phone Number</h3>
                <input
                  type="text"
                  required
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="    Enter your phone number"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "4vh" }}>
              <div>
                <h3>Email id</h3>
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  placeholder="    Enter your email-id"
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              {/* <div>
                <h3 style={{ marginLeft: "2vw" }}>Password</h3>
                <input
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="text"
                  placeholder="    Enter Password"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div> */}
            </div>
            <div style={{ display: "flex", marginTop: "4vh" }}>
              <div>
                <h3>Zip Code</h3>
                <input
                  required
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  type="text"
                  placeholder="   Enter zip code"
                  style={{ height: "4.6vh", width: "19vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>City</h3>
                <input
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  placeholder="    City"
                  style={{ height: "4.6vh", width: "19vw", marginLeft: "2vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>State</h3>

                <select

                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                  style={{ height: "5vh", width: "30vw", marginLeft: "2vw" }}
                >
                  <option selected value=""></option>
                  {states.map((state) => {
                    return (
                      <>
                        <option>{state}</option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "4vh" }}>
              <div>
                <h3>Language</h3>
                <input
                  type="text"
                  placeholder="    Enter language"
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Business type</h3>
                <input
                  required
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  type="text"
                  placeholder="    Business type"
                  style={{ height: "4.6vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <button
              style={{
                color: "#FFFFFF",
                background: "#7400B8",
                height: "4vh",
                width: "10vw",
                marginTop: "4vh",
                marginLeft: "54vw",
                border: "none",
                borderRadius: "9px",
                cursor: "pointer",
              }}
              type="submit"
              disabled={isSubmitting}
            >
              ADD
            </button>
            {isSubmitting && (
              <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                Loading...
              </span>
            )}
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              success
              theme="dark"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddUser;
