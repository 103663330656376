import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import blueDown from "./blueDown.png";
import { admin, baseUrl } from "../localdata";
import IPUrl from "../IP/url";

const Bills = () => {
  const [adminId, setId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/showAllBills/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  const toAdd = () => {
    navigate("/upload/bill")
  }

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <div
          className="tas"
          style={{ background: "#EFEFEF", height: "100vh", width: "100%" }}
        >
          <div
            className="container"
            style={{
              height: "89vh",
              width: "90%",
              overflow: "auto",
              marginTop: "1vh",
              paddingLeft: "2vw",
              paddingRight: "2vw",
            }}
          >
            <div
              style={{
                width: "18vw",
                marginLeft: "60vw",
                paddingTop: "4vh",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={toAdd}
                style={{
                  cursor: "pointer",
                  height: "4vh",
                  width: "6vw",
                  borderRadius: "6px",
                  background: "#7400B8",
                  border: "none",
                  color: "#FFFFFF",
                }}
              >
                Upload
              </button>
            </div>

            {/* {data.map((details, i) => {
              return (
                <>
                  <div key={i}>
                    <div>
                      <h3 style={{ marginBottom: "-0.8vh" }}>{details.date}</h3>
                      <hr
                        style={{
                          marginLeft: "9vw",
                          color: "1px solid #CDCDCD",
                          display:
                            details.date === undefined ? "none" : "block",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        borderRadius: "10px",
                        background: "#FFFFFF",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginLeft: "4vw" }}>
                        <h3>
                          Savings Account/payment
                          <br />
                          {details.ac}
                        </h3>
                        <h3 style={{ color: "gray" }}>
                          Chq/Ref No.:
                          <br />
                          Savings Account-{details.ref}
                        </h3>
                      </div>
                      <div style={{ display: "flex", width: "12vw" }}>
                        <h3
                          style={{
                            color: details.id !== "2" ? "green" : "red",
                            marginRight: "2vw",
                            alignSelf: "center",
                          }}
                        >
                          {details.trans}
                        </h3>
                        <img
                          style={{ height: "1.8vh", alignSelf: "center" }}
                          src={blueDown}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })} */}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Bills;
