import { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import useSWR from "swr";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { admin, baseUrl } from "../localdata";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import IPUrl from "../IP/url";


const Payments = ({ searchQuery, onSearch }) => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/showAllPayment/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
  console.log(data, "payment list data");

  const filtered = data?.filter((payment) => {
    return payment.number
      ?.toString()
      ?.trim()
      .toLowerCase()
      .includes(searchQuery);
  });

  const [newData, setNewData] = useState(data);

  useEffect(() => {
    if (searchQuery?.length < 2 || searchQuery == "") {
      setNewData(data);
    } else {
      setNewData(filtered);
    }
  }, [searchQuery, data]);

  const navigate = useNavigate();

  const toAdd = () => {
    navigate("/addpay");
  };
  const [index, setIndex] = useState("");
  const [isOpen, setisOpen] = useState(false);
  function handleMenuIconClick(id) {
    console.log(id, "get id on click");
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  const open2 = true;
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar onSearch={onSearch} />
        {!data ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div
            style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
          >
            <div
              style={{
                width: "18vw",
                marginLeft: "60vw",
                paddingTop: "4vh",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={toAdd}
                style={{
                  cursor: "pointer",
                  height: "4vh",
                  width: "6vw",
                  borderRadius: "6px",
                  background: "#7400B8",
                  border: "none",
                  color: "#FFFFFF",
                }}
              >
                Upload
              </button>
            </div>
            <div
              className="container"
              style={{ width: "78vw", marginLeft: "1vw", overflow: "auto" }}
            >
              <table className="tab" style={{ width: "100%" }}>
                <tr className="tabtr">
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    S. No.
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>Date</th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>Time</th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Full Name
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Mobile Number
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Product Name
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Price
                  </th>
                  <th style={{ textAlign: "center", color: "#7400B8" }}>
                    Quantity
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Payment
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>Paid</th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Order No.
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Action.
                  </th>
                </tr>

                {newData?.map((item, i) => (
                  <tr
                    key={i}
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {moment(item?.paymentDate).format("DD-MM-YYYY")}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.paymentTime}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.number}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.productName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.netPrice}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.orderQuantity}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.totalOutstandingPayment}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.totalPaidAmount}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.orderNumber}
                    </td>
                    <td
                      style={{
                        "font-size": "15px",
                        textAlign: "center",
                      }}
                    >
                      <a
                        onClick={() => handleMenuIconClick(item._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <BiDotsVerticalRounded />
                      </a>
                    </td>
                    <div
                      style={{
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        display: index === item._id ? "block" : "none",
                        position: "absolute",
                        right: "1.5vw",
                      }}
                    >
                      <>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/update/pay/${item._id}`}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                              textDecoration: "none",
                            }}
                          >
                            <MdModeEditOutline
                              style={{ paddingRight: "2vw" }}
                            />
                            <h5 style={{ margin: "0" }}>Edit</h5>
                          </div>
                        </Link>
                        {/* <div style={{ cursor:"pointer",display:"flex",padding:"4px",borderBottom:"1px solid black" }}><MdDelete style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Block</h5> </div> */}
                      </>
                    </div>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Payments;
