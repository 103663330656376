import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import Navbar from "../Navbar/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../Sidebar/Sidebar";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import upload from "./medium.png";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import up from "./up.png";
import useSWR from "swr";
import axios from "axios";
import { Link } from "react-router-dom";
import { admin, baseUr, baseUrl } from "../localdata";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Board from "../Board/Board";
import EnquiryDetail from "./EnquiryDetial";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import IPUrl from "../IP/url";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const Enquiry = ({ onSearch, searchQuery }) => {
  const [adminId, setId2] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId2(admin._id);
    }
  });

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const open2 = true;
  const [id, setId] = useState("");
  const [responseImage, setResponseImage] = useState("");
  const [enqData, setEnq] = useState("");
  // const [enquiryMsg, setMessage] = useState("");
  const [enquiryImage, setImage] = useState("");

  const imageref = useRef(null);
  const spanref = useRef(null);

  console.log(id, "id state value here");

  console.log(adminId, "admin id here");
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/dashboardEnquiryData/${adminId}/?offset=10`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  const [open, setOpen] = useState(false);
  const handleModelOpen = (id, image, enquiry) => {
    setOpen(true);
    setId(id);
    setResponseImage(image);
    setEnq(enquiry);
    console.log(id, "id");
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const handleChange = useCallback((e) => {
  //   setMessage(e.target.value);
  // }, []);

  // submit enquiry form->

  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  //   console.log(enquiryImage, "line 134");
  //   console.warn(e.target.files[0]);
  // };
  // const enquiryMsg = inputref?.current?.value;

  // console.log(enquiryMsg, "line 91");
  // async function submitForm() {
  //   const myForm = new FormData();
  //   myForm.append("enquiryMsg", inputref?.current?.value);

  //   // if (imageref && imageref.current) {
  //   //   // Check that files is not null or undefined and that a file is selected
  //   //   if (imageref.current.files && imageref.current.files[0]) {
  //   //     const enquiryImage = imageref.current.files[0];
  //   //     myForm.append("enquiryImage", enquiryImage);
  //   //     console.log('Selected file:', enquiryImage);
  //   //   }
  //   // }
  //   try {
  //     const { data } = await axios.post(
  //       `/admin/sendEnquiry/${adminId}/${id}`,

  //       myForm,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     toast("Enquiry Sent");
  //     console.log(data);
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //     console.log(error);
  //   }
  // }

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const reverseEnquiry = data?.enquiryList?.reverse();
  const filtered = reverseEnquiry?.filter((enquiry) => {
    return enquiry?.user?.enquiry?.trim().toLowerCase().includes(searchQuery);
  });

  const [newData, setNewData] = useState(reverseEnquiry);

  useEffect(() => {
    if (searchQuery?.length < 2 || searchQuery == "") {
      setNewData(reverseEnquiry);
    } else {
      setNewData(filtered);
    }
  }, [searchQuery, data]);
  const TheModal = () => {
    return (
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            {/* enquiry form with details in popup */}
            <EnquiryDetail id={id} />
          </Box>
        </Modal>
      </div>
    );
  };
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar onSearch={onSearch} />
        {!data ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div
            style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
          >
            <div style={{ display: "flex", marginLeft: "10vw" }}>
              <Board style={{}} />
            </div>
            <div
              className="container"
              style={{
                height: "70vh",
                width: "78vw",
                marginLeft: "1vw",
                overflow: "auto",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>Enquiry</h2>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "4vw",
                  }}
                >
                  <AiOutlineArrowLeft style={{ cursor: "pointer" }} />
                  <AiOutlineArrowRight
                    style={{ marginLeft: "1vw", cursor: "pointer" }}
                  />
                </div>
              </div>

              <hr style={{ width: "77vw" }} />
              <table className="tab" style={{ width: "100%" }}>
                <tr className="tabtr">
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Sr No.
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Full Name
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Email
                  </th>
                  <th style={{ textAlign: "start", color: "#7400B8" }}>
                    Enquiry
                  </th>
                </tr>

                {newData?.map((item, i) => (
                  <tr key={item._id} style={{ paddingTop: "2vh" }}>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.user?.userId?.fullName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.user?.userId?.email}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.user?.enquiry}
                    </td>

                    <button
                      onClick={() =>
                        handleModelOpen(
                          item._id,
                          item.user?.images,
                          item.user?.enquiry
                        )
                      }
                      style={{
                        cursor: "pointer",
                        width: "6vw",
                        height: "4vh",
                        color: "#FFFFFF",
                        background: "#7400B8",
                        border: "none",
                        borderRadius: "6px",
                      }}
                    >
                      Open
                    </button>
                  </tr>
                ))}
              </table>
            </div>
            <>
              <TheModal />
            </>
          </div>
        )}
      </div>
    </div>
  );
};
export default Enquiry;
