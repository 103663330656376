import React from 'react';

function Contact() {
    const themeColor = '#0F9EE8';

    const containerStyle = {      
      backgroundColor: themeColor,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      color: '#fff',
    };
  
    const textContainerStyle = {
      color: '#fff',
      textAlign: 'center',
    };
  
    return (
      <div style={containerStyle}>
          <h1>Contact Us</h1>
        <div style={textContainerStyle}>
          <p>Feel free to get in touch with us for any inquiries or assistance.</p>
        </div>
        <h2>MARUTI ENTERPRISES</h2>
        <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',width:'100%',height:'50vh'}}>
  
          <div style={{
            height:'10vmax',
            width:'20vmax',
            backgroundColor:'white',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:'10px',
            boxShadow:'0px 0px 10px 0px rgba(0,0,0,0.3)',
            padding:'10px',
            color:'#0F9EE8'
          }}>
            <h2>Email Us:</h2>
            <p><a style={{
                color:'#0F9EE8'
            }} href="mailto:msteelinfo@gmail.com">msteelinfo@gmail.com</a></p>
          </div>
  
          <div
          style={{
            height:'10vmax',
            width:'20vmax',
            backgroundColor:'white',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:'10px',
            boxShadow:'0px 0px 10px 0px rgba(0,0,0,0.3)',
            padding:'10px',
            color:'#0F9EE8'
          }}
          >
            <h2>Phone (WhatsApp) No:</h2>
            <p>+91 <a style={{
                color:'#0F9EE8',
                textDecoration:'none'
            }}
             href="https://wa.me/9425501751">9425501751</a></p>
          </div>
  
          <div
          style={{
            height:'10vmax',
            width:'20vmax',
            backgroundColor:'white',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:'10px',
            boxShadow:'0px 0px 10px 0px rgba(0,0,0,0.3)',
            padding:'10px',
            color:'#0F9EE8'
          }}
          >
            <h2>Address:</h2>
            <p>Maruti Enterprises, 10 Arihant Complex<br />Station Road, Raipur</p>
          </div>
          </div>
          <div style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'10vh',
            gap:'2vmax'
          }}>
            <button
            style={{
                padding:'0.5vmax 1vmax',
                borderRadius:'10px',
                border:'none',
                backgroundColor:'transparent',
                color:'#fff',
                textDecoration:'underline',
                fontSize:'1.5vmax',
                cursor:'pointer'
            }}
            onClick={()=>{
                window.location.href='/login'
            }}

            onMouseOver={(e)=>{
                e.target.style.color='blue';
            }}

            onMouseOut={(e)=>{
                e.target.style.color='#fff';
            }}

            >Go to Login In</button>
          </div>
      </div>
    );
}

export default Contact;