import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { admin, baseUrl, states } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import CreatableSelect from "react-select/creatable";
import "./stockname.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IPUrl from "../IP/url";

const StockFeatO = () => {
  const location = useLocation();
  const type = location.state?.type;
  console.log(type, "type inside manage rates form");
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });

  const [loadingName, setLoadingName] = useState(false);
  const [stockNames, setStockNames] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [stateName, setState] = useState("");
  const [selectStock, setSelectStock] = useState(null);
  const stockName = selectStock?.value;
  const [basic, setBasicrate] = useState("");
  const [loading, setLoadingrate] = useState("" || 0);
  const [insurance, setInsurance] = useState("" || 0);
  const [gst, setGst] = useState("" || 0);
  const [tcs, setTcs] = useState("" || 0);
  const [businessType, setBusinessType] = useState("");
  const [sizes, setSizes] = useState([
    {
      size: "",
      amount: "",
    },
  ]);

  const handleFielInputChange = (event, index) => {
    let data = [...sizes];
    data[index][event.target.name] = event.target.value;
    setSizes(data);
  };

  function addField() {
    let object = {
      size: "",
      amount: "",
    };
    setSizes([...sizes, object]);
  }

  function removeField(index) {
    let data = [...sizes];
    data.splice(index, 1);
    setSizes(data);
  }

  console.log(stockNames, "stock name");
  const open2 = true;

  //  handlechange for state->
  const handleSelectState = async (e) => {
    setState(e.target.value);
    const stateName = e.target.value;
    setLoadingName(true);
    // fetch data on select state
    const { data } = await axios.get(
      `${IPUrl}/admin/stateList/${stateName}/${type}/${adminId}`
    );
    setStockNames(data);
    setLoadingName(false);
  };
  // create options for react select->
  const options = stockNames.map((i) => {
    return {
      value: i,
      label: i,
    };
  });

  const date = new Date();
  const newDate = date.toISOString().substring(0, 10);
  const stockDate = newDate;
  console.log(date, "stock date");

  console.log(stockName, "line 120");

  const submitForm = async (formdata) => {
    try {
      const { data } = await axios.post(`${IPUrl}/admin/uploadStock/${adminId}`, {
        stateName,
        stockName,
        businessType,
        stockDate,
        gst,
        tcs,
        insurance,
        basic,
        loading,
        sizes,
      });
      console.log(data, "form data");
      toast(data?.message);
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <form onSubmit={handleSubmit(submitForm)}>
          {loadingName ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{
                height: "100vh",
                overflow: "scroll",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
              <div style={{ display: "flex", paddingTop: "6vh" }}>
                <div>
                  <h3>State</h3>
                  <select
                    value={stateName}
                    onChange={handleSelectState}
                    required
                    style={{ height: "4vh", width: "30vw" }}
                  >
                    <option selected value=""></option>
                    {states.map((state) => {
                      return (
                        <>
                          <option>{state}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Stock Name</h3>
                  <CreatableSelect
                    className="createable-stock-name-option"
                    isClearable
                    options={options}
                    onChange={setSelectStock}
                  />
                  {/* <input
                  type="text"
                  required
                  value={stockName}
                  onChange={(e) => setStockName(e.target.value)}
                  
                  placeholder="    Stock Name"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                /> */}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Basic Rate</h3>
                  <input
                    required
                    value={basic}
                    onChange={(e) => setBasicrate(e.target.value)}
                    type="number"
                    placeholder="    Basic Rate."
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Loading Rate</h3>
                  <input
                    type="number"
                    value={loading}
                    onChange={(e) => setLoadingrate(e.target.value)}
                    placeholder="   Loading Rate"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Insurance</h3>
                  <input
                    type="number"
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                    placeholder="    Insurance."
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>GST</h3>
                  <input
                    type="number"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                    placeholder="   GST"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>TCS</h3>
                  <input
                    type="number"
                    value={tcs}
                    onChange={(e) => setTcs(e.target.value)}
                    placeholder="    TCS"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Business Type</h3>
                  <select
                    required
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                    name=""
                    id=""
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  >
                    <option value="" selected></option>
                    <option>B2C</option>
                    <option>B2B</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid black",
                  width: "63vw",
                  marginTop: "2vh",
                  padding: "10px",
                }}
              >
                {sizes.map((value, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",

                          paddingBottom: "6vh",
                        }}
                      >
                        <div>
                          <h3>Size</h3>
                          <input
                            type="text"
                            required
                            name="size"
                            value={value.size}
                            onChange={(event) =>
                              handleFielInputChange(event, index)
                            }
                            placeholder="    Size."
                            style={{ height: "4vh", width: "30vw" }}
                          />
                        </div>
                        <div>
                          <h3 style={{ marginLeft: "2vw" }}>Amount</h3>
                          <input
                            type="text"
                            required
                            name="amount"
                            value={value.amount}
                            onChange={(event) =>
                              handleFielInputChange(event, index)
                            }
                            placeholder="   Amount"
                            style={{
                              height: "4vh",
                              width: "30vw",
                              marginLeft: "2vw",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "2vh",
                            }}
                          >
                            <button
                              style={{ background: "crimson", color: "white", border: 'none', outline: 'none', cursor: 'pointer', padding: '6px', borderRadius: '5px' }}
                              onClick={() => removeField(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <button
                type="button"
                onClick={addField}
                style={{
                  color: "#FFFFFF",
                  background: "#7400B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "2vmax",
                  marginLeft: "50vmax",
                  border: "none",
                  borderRadius: "9px",
                  cursor: "pointer",
                }}
              >
                Add Field
              </button>
              {/* <div style={{ display: "flex" }}>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Thickness</h3>
                  <input
                    type="text"
                    required
                    value={thickness}
                    onChange={(e) => setThickness(e.target.value)}
                    
                    placeholder="   Thickness"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div> */}
              {/* <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "flex",
                    border: "1px solid black",
                    position: "relative",
                    height: "20vh",
                    width: "66vw",
                    marginLeft: "-2vw",
                    paddingLeft: "0vw",
                    marginTop: "4vh",
                    paddingBottom: "6vh",
                  }}
                >
                  <div>
                    <h3 style={{ marginLeft: "2vw" }}>Field Name</h3>
                    <input
                      type="text"
                      ref={inputRef}
                      placeholder="    Enter Field Name"
                      style={{
                        height: "4vh",
                        width: "30vw",
                        marginLeft: "2vw",
                      }}
                    />
                  </div>
                 
                  <button
                    type="button"
                    onClick={addField}
                    style={{
                      color: "#FFFFFF",
                      background: "#7400B8",
                      height: "4vh",
                      width: "10vw",
                      marginTop: "1vh",
                      marginLeft: "23vw",
                      border: "none",
                      borderRadius: "9px",
                    }}
                  >
                    Add Field
                  </button>
                </div>

                <button
                  type="button"
                  onClick={() => setisOpen(true)}
                  style={{
                    color: "#FFFFFF",
                    background: "#7400B8",
                    height: "4vh",
                    width: "10vw",
                    marginTop: "1vh",
                    marginLeft: "54vw",
                    border: "none",
                    borderRadius: "9px",
                  }}
                >
                  Create Table
                </button>
              </div> */}

              {/* <div
                style={{
                  display: isOpen === true ? "block" : "none",

                  position: "relative",
                  width: "66vw",
                  marginLeft: "0vw",
                  paddingLeft: "2vw",
                  marginTop: "4vh",
                  paddingBottom: "2vh",
                  marginBottom: "2vh",
                }}
              >
               
                {data &&
                  data.map((item, index) => {
                    return (
                      <div key={index}>
                        <h3>{item.label}</h3>
                        <input
                          type="text"
                          
                          value={item.value}
                          placeholder="   "
                          onChange={(event) => handleInputChange(event, index)}
                          style={{ height: "4vh", width: "30vw" }}
                        />
                      </div>
                    );
                  })}
              </div> */}
              <div></div>
              <button
                disabled={isSubmitting}
                style={{
                  color: "#FFFFFF",
                  background: "#7400B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "4vh",
                  marginLeft: "53vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                type="submit"
              >
                Upload
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
export default StockFeatO;
