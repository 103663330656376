import { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import Board from "../Board/Board";
import axios from "axios";
import { admin, baseUrl } from "../localdata";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { IoPrintSharp } from "react-icons/io5";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import "./one.css";
import IPUrl from "../IP/url";

const RegisteredUsers = () => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` ${IPUrl}/admin/dashboardUserData/${adminId}/?offset=${10}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );


  const [block, setblock] = useState(false);

  const [index, setIndex] = useState(null);
  const [index1, setIndex1] = useState(null);
  const [edit, setedit] = useState(false);
  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  const handleblock = (id) => {
    setblock(!block);
    if (index == id) {
      setIndex1(null);
    } else {
      setIndex1(id);
    }
  };

  //   delete user api->

  const handleDelete = async (userId) => {
    try {
      const { data } = await axios.post(
        `${IPUrl}/admin/delete/user/${adminId}`,
        { userId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert("User deleted");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <div style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}>
          <div
            className="container"
            style={{
              height: "87.5vh",
              width: "78vw",
              marginLeft: "1vw",
              position: "relative",
              overflow: "auto",
            }}
          >
            <div style={{ display: "flex", marginLeft: "10vw" }}>
              <Board style={{}} />
            </div>
            <table className="tab" style={{ width: "100%" }}>
              <tr className="tabtr">
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Full Name{" "}
                </th>
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Mobile Number{" "}
                </th>
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Email Id{" "}
                </th>
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Zip Code{" "}
                </th>
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Choose Language
                </th>
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Choose City{" "}
                </th>
                <th style={{ textAlign: "center", color: "#7400B8" }}>
                  Choose State{" "}
                </th>
                <th style={{ textAlign: "start", color: "#7400B8" }}>
                  Business Type{" "}
                </th>
              </tr>

              {data &&
                data?.userList?.map((item, i) => (
                  <tr
                    key={i}
                    className={block && index == item.id ? "blocked" : ""}
                    style={{ paddingTop: "2vh" }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.number}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.email}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.zipCode}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.language || "English"}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.city}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.state}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.business || "B2B"}
                    </td>
                    <td
                      style={{
                        "font-size": "15px",
                        textAlign: "center",
                      }}
                    >
                      <a
                        onClick={() => handleMenuIconClick(item._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <BiDotsVerticalRounded />
                      </a>
                    </td>
                    <div
                      style={{
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        display: index === item._id ? "block" : "none",
                        position: "absolute",
                        right: "5vw",
                      }}
                    >
                      <>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/update/user/${item._id}`}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <MdModeEditOutline
                              style={{ paddingRight: "2vw" }}
                            />
                            <h5 style={{ margin: "0" }}>Edit</h5>{" "}
                          </div>
                        </Link>
                        <div
                          onClick={() => handleDelete(item._id)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            padding: "4px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <MdDelete style={{ paddingRight: "2vw" }} />
                          <h5 style={{ margin: "0" }}>Delete</h5>{" "}
                        </div>
                        {/* <div style={{cursor:"pointer",display:"flex",padding:"4px" }}><IoPrintSharp style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Print</h5> </div> */}
                      </>
                    </div>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(RegisteredUsers);
