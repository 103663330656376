import {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesList from './RoutesList';


function App() {

 
  return (

    <Router>
       <RoutesList />

    </Router>
  );
}

export default App;
