import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import useSWR from "swr";
import axios from "axios";
import MonthlyGraph from "./MonthlyGraph";
import WeeklyGraph from "./WeeklyGraph";
import YearlyGraph from "./YearlyGraph";
import IPUrl from "../IP/url";

const Graph = () => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(` ${IPUrl}/admin/stocks/yearly`, fetcher, {
    refreshInterval: 1000,
  });
  const navigate = useNavigate();
  const [index, setIndex] = useState(null);

  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  const [open, setopen] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [open3, setopen3] = useState(false);
  const [activeWeeklyGraph, setActiveWeeklyGraph] = useState(true);
  const [activeMonthlyGraph, setActiveMonthlyGraph] = useState(false);
  const [activeYearlyGraph, setActiveYearlyGraph] = useState(false);
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <div
          style={{
            height: "92vh",
            width: "80vw",
            background: "#EFEFEF",
            paddingTop: "8vh",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "6vh",
              width: "80vw",
              background: "#7400B8",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "80vw",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {/* <div>
                <h3
                  onClick={() => {
                    setopen(!open);
                  }}
                  style={{ cursor: "pointer", color: "#FFFFFF" }}
                >
                  Day
                </h3>
                <hr
                  style={{
                    marginTop: "-1.5vh",
                    display: open === true ? "block" : "none",
                    border: "2px solid #FFFFFF",
                    borderRadius: "6px",
                  }}
                />
              </div> */}
              <div>
                <h3
                  onClick={() => {
                    setActiveMonthlyGraph(false);
                    setActiveWeeklyGraph(true);
                    setActiveYearlyGraph(false);
                  }}
                  style={{ cursor: "pointer", color: "#FFFFFF" }}
                >
                  Week
                </h3>
                <hr
                  style={{
                    marginTop: "-1.5vh",
                    display: activeMonthlyGraph === true ? "block" : "none",
                    border: "2px solid #FFFFFF",
                    borderRadius: "6px",
                  }}
                />
              </div>
              <div>
                <h3
                  onClick={() => {
                    setActiveMonthlyGraph(true);
                    setActiveWeeklyGraph(false);
                    setActiveYearlyGraph(false);
                  }}
                  style={{ cursor: "pointer", color: "#FFFFFF" }}
                >
                  Month
                </h3>
                <hr
                  style={{
                    marginTop: "-1.5vh",
                    display: activeYearlyGraph === true ? "block" : "none",
                    border: "2px solid #FFFFFF",
                    borderRadius: "6px",
                  }}
                />
              </div>
              <div>
                <h3
                  onClick={() => {
                    setActiveYearlyGraph(true);
                    setActiveWeeklyGraph(false);
                    setActiveMonthlyGraph(false);
                  }}
                  style={{ cursor: "pointer", color: "#FFFFFF" }}
                >
                  Year
                </h3>
                <hr
                  style={{
                    marginTop: "-1.5vh",
                    display: activeYearlyGraph === true ? "block" : "none",
                    border: "2px solid #FFFFFF",
                    borderRadius: "6px",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="container"
            style={{
              height: "70vh",
              width: "78vw",
              marginLeft: "1vw",
              overflow: "auto",
              zIndex: "0",
            }}
          >

            {activeWeeklyGraph ? (
              <WeeklyGraph />
            ) : activeMonthlyGraph ? (
              <MonthlyGraph />
            ) : activeYearlyGraph ? (
              <YearlyGraph />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Graph;
